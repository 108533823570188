import React from 'react'
import { styled } from 'styled-components'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
} from 'react-share'
import { BsFacebook, BsLinkedin, BsReddit, BsTwitter, BsWhatsapp } from '../../utils/GetIcons'
import Reveal from '../../utils/Reveal'

const ShareContainer = styled.div`
  display: flex;
  margin: auto;
  width: min-content;
`

const IconContainer = styled.div`
  cursor: pointer;
  color: ${props => props.theme.primary700to300};
  display: flex;
  padding: 0.5rem 0.75rem;
  @media (min-width: 480px) {
    transition:
      filter 200ms ease-in-out,
      transform 200ms ease-in-out;
    &:focus,
    &:hover {
      filter: brightness(1.5);
      transform: translateY(-0.25rem);
    }
  }
`

interface Props {
  title: string
  url: string
  tags: string[]
}

const ShareButtons = ({ title, url, tags }: Props): JSX.Element => {
  const iconSize = 30

  const shareButtons = [
    { button: FacebookShareButton, icon: BsFacebook },
    { button: TwitterShareButton, icon: BsTwitter },
    { button: LinkedinShareButton, icon: BsLinkedin },
    { button: RedditShareButton, icon: BsReddit },
    { button: WhatsappShareButton, icon: BsWhatsapp },
  ]

  return (
    <ShareContainer>
      {shareButtons.map((shareButton, index) => {
        const { button: ShareButton, icon: Icon } = shareButton
        return (
          <Reveal key={index} effect="fade-up" delay={index}>
            <ShareButton key={index} url={url} title={title}>
              <IconContainer>
                <Icon size={iconSize} />
              </IconContainer>
            </ShareButton>
          </Reveal>
        )
      })}
    </ShareContainer>
  )
}

export default ShareButtons
