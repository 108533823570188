/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import * as React from 'react'
import styled, { css } from 'styled-components'
import { GatsbyImage, type IGatsbyImageData, getImage } from 'gatsby-plugin-image'

import { BsFillCalendarEventFill, BsClockHistory, BsFillGeoAltFill } from '../../../utils/GetIcons'
import Capitalize from '../../../utils/Capitalize'
import Describer from '../../common/text/DescriberTag'
import HeaderDescription from '../../common/text/HeaderDescription'
import HeaderImageOverlay from '../../common/HeaderImageOverlay'
import HeaderContainer from '../../common/ImageHeaderContainer'
import ShareButtons from '../../elements/ShareButtons'

const Container = styled(HeaderContainer)`
  @media (min-width: 980px) {
    flex-direction: row;
    height: 91dvh;
    padding: 0;
    margin-top: 4rem;
  }
`

const TextContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 40rem;
  padding: var(--spacing-header-1);
  padding-bottom: 0;
  text-align: center;
  width: 100%;
  z-index: 2;
  @media (min-width: 480px) {
    padding: var(--spacing-header-2);
    padding-bottom: 0;
  }
  @media (min-width: 980px) {
    margin: auto 0 auto 0;
    text-align: start;
    width: calc(38vw - 1rem);
  }
`

const PostDescriber = styled(Describer)`
  margin-bottom: 0.25rem;
`

const Title = styled.h1`
  color: ${props => props.theme.black200greyscale100};
  font: 700 var(--fontSize-6) / 1 var(--font-dela);
  letter-spacing: var(--letterSpacing-1);
  margin: 0;
  text-align: center;
  transition: color 300ms ease-in-out;
  @media (min-width: 768px) {
    font-size: var(--fontSize-7);
  }
  @media (min-width: 980px) {
    text-align: start;
  }
`

const Description = styled(HeaderDescription)`
  text-align: start;
  margin: 0.5rem auto 1rem auto;
  max-width: 40rem;
`

const AkaSubtitle = styled.div`
  color: ${props => props.theme.greyscale500secondary200};
  font: 500 var(--fontSize--1) / 1.5 var(--font-montserrat);
  font-style: italic;
  letter-spacing: var(--letterSpacing-0);
  margin: 1rem 0 -0.5rem 0;
  @media (min-width: 1200px) {
    font-size: var(--fontSize-0);
  }
`

const InfoList = styled.ul`
  border-bottom: 2px solid ${props => props.theme.greyscale600to400};
  border-top: 2px solid ${props => props.theme.greyscale600to400};
  margin: 1.5rem auto;
  padding: 0;
  width: 100%;
`

const InfoItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0.25rem;
  &:not(:last-child) {
    border-bottom: 2px solid ${props => props.theme.greyscale400to600};
  }
`

const InfoIcon = styled.div`
  align-items: center;
  display: flex;
  color: ${props => props.theme.primary700to300};
  margin: auto 0.5rem auto 0;
  justify-content: center;
`

const PostInfo = css`
  color: ${props => props.theme.black300greyscale200};
  font: 500 var(--fontSize-0) / 1.5 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-0);
  @media (min-width: 1200px) {
    font-size: var(--fontSize-1);
  }
`

const InfoTitle = styled.h4`
  ${PostInfo}
  font-weight: 400;
  margin: 0 auto 0 0;
`

const InfoDescription = styled.h4`
  ${PostInfo}
  font-weight: 500;
  margin: 0 0 0 auto;
`

const ImageContainer = styled.div`
  align-self: center;
  display: flex;
  margin-top: 1.5rem;
  position: relative;
  width: calc(100% + 3rem);
  @media (min-width: 980px) {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
    width: 62vw;
  }
`

const CoverImage = styled(GatsbyImage)`
  height: auto;
  width: auto;
  @media (min-width: 980px) {
    height: 91dvh;
    width: 62vw;
  }
`

const getIcon = (type: string): JSX.Element => {
  const iconSize = 16
  switch (type) {
    case 'Location':
      return <BsFillGeoAltFill size={iconSize} />
    case 'Date':
      return <BsFillCalendarEventFill size={iconSize} />
    case 'Days Until Event':
      return <BsClockHistory size={iconSize} />
    default:
      return <></>
  }
}

const getInfo = (type: string, item: string, prop?: string): JSX.Element => {
  return (
    <InfoItem>
      <InfoIcon>{getIcon(type)}</InfoIcon>
      <InfoTitle>{Capitalize(type)}</InfoTitle>
      {item && <InfoDescription {...(prop && { itemProp: prop })}>{item}</InfoDescription>}
    </InfoItem>
  )
}

interface Props {
  frontmatter: any
  url: string
}

export default function PostHeader({ frontmatter, url }: Props): JSX.Element {
  const { hero_image: image, title, type, description } = frontmatter
  let typeText = type
  let tag: string = ''
  let date: any
  let whereText: string = ''
  let daysUntil: string = ''

  if (type === 'article') {
    date = new Date(frontmatter.date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    })
  } else if (type === 'event') {
    const currentDateTime = new Date().getTime()
    const startDateObj = new Date(frontmatter.startdate)
    const endDateObj = new Date(frontmatter.enddate)
    tag = 'Experience'
    typeText = 'Date'
    whereText = `${frontmatter.city ? frontmatter.city + ', ' : ''}${frontmatter.country}`

    const days = Math.ceil((startDateObj.getTime() - currentDateTime) / (1000 * 60 * 60 * 24))
    daysUntil =
      days < 0
        ? currentDateTime > endDateObj.getTime()
          ? `${new Date().getFullYear()} Event Finished!`
          : 'On Now!'
        : `${days.toString()} days to go!`

    const startDate = startDateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
    const endDate = endDateObj.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })
    const endYear = endDateObj.getFullYear()
    date = startDate === endDate ? `${endDate}, ${endYear}` : `${startDate} to ${endDate}, ${endYear}`
  } else if (type === 'route') {
    tag = 'Explore'
  }

  return (
    <Container>
      <TextContainer>
        {type !== 'article' && <PostDescriber>{tag}</PostDescriber>}
        <Title>{title}</Title>
        {type === 'event' && frontmatter.aka && <AkaSubtitle>{`Also known as ${frontmatter.aka}`}</AkaSubtitle>}
        <InfoList>
          {type === 'event' && getInfo('Location', whereText, 'location')}
          {getInfo(typeText, date)}
          {type === 'event' && getInfo('Days Until Event', daysUntil)}
        </InfoList>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <AkaSubtitle style={{ margin: '-0.5rem 0 0.5rem 0' }}>
            {`Last modified `}
            {new Date(frontmatter.lastMod).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })}
          </AkaSubtitle>
          <AkaSubtitle style={{ margin: '-0.5rem 0 0.5rem 0' }}>Written by Daniel Thurston</AkaSubtitle>
        </div>
        <Description key={description}>{description}</Description>
        <ShareButtons title={title} url={url} tags={[]} />
      </TextContainer>
      {image !== undefined && (
        <ImageContainer>
          <CoverImage alt={title} image={getImage(image) as IGatsbyImageData} />
          <HeaderImageOverlay />
        </ImageContainer>
      )}
    </Container>
  )
}
