import React, { lazy, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Disqus } from 'gatsby-plugin-disqus'

import Layout from '../components/layouts/layout'
import Seo from '../components/seo'
import PostHeader from '../components/sections/headers/PostHeader'
import SectionHeader from '../components/sections/headers/SectionHeader'
import Capitalize from '../utils/Capitalize'
import { POST_TYPE_CONSTANTS } from '../constants'
import Reveal from '../utils/Reveal'
import addSuspense from '../utils/AddSuspense'
import ShareButtons from '../components/elements/ShareButtons'
import { useSelector } from 'react-redux'

const EventCard = lazy(async () => await import('../components/elements/cards/EventCard'))
const PostCard = lazy(async () => await import('../components/elements/cards/PostCard'))
const RouteCard = lazy(async () => await import('../components/elements/cards/RouteCard'))

const PostBody = styled.section`
  color: ${props => props.theme.black300greyscale200};
  font-family: var(--font-lora);
  font-size: var(--fontSize-2);
  max-width: 56rem;
  margin: 0 auto;
  transition: color 300ms ease-in-out;
  padding: var(--spacing-section-2);
  padding-bottom: 0.5rem;
`

const SectionTitle = styled.h2`
  color: ${props => props.theme.black200greyscale100};
  font: 900 var(--fontSize-4) / 1.5 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-0);
  margin: 0 1.5rem 0.5rem 1.5rem;
  text-align: center;
  user-select: none;
  @media (min-width: 768px) {
    font-size: var(--fontSize-5);
  }
`

const CommentsContainer = styled.div`
  margin: 1rem auto;
  padding: var(--spacing-section-1);
  max-width: 50rem;
  @media (min-width: 480px) {
    padding: var(--spacing-section-2);
  }
`

const Footer = styled.section`
  margin-top: 2rem;
  @media (min-width: 768px) {
    margin-top: 0.5rem;
  }
`

const Nav = styled.nav`
  padding: var(--spacing-section-1);
  padding-top: 0;
  max-width: 60rem;
  margin: 0 auto 1rem auto;
  @media (min-width: 480px) {
    padding: var(--spacing-section-2);
    padding-top: 0;
  }
`

interface Props {
  data: any
  location: any
}

export default function BlogPostTemplate({
  data: { previous, next, site, markdownRemark: post },
  location,
}: Props): JSX.Element {
  const [themeKey, setThemeKey] = useState(0)
  const isDark: boolean = useSelector((state: any) => state.dark.mode)
  const { frontmatter } = post
  const { type, title } = frontmatter
  const textConstants = POST_TYPE_CONSTANTS[`${type}s` as 'events' | 'articles' | 'routes'] ?? {}
  const postLink = `${site.siteMetadata.siteUrl}${location.pathname}`
  const disqusConfig = {
    url: postLink,
    identifier: postLink,
    title,
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setThemeKey(prevKey => prevKey + 1)
    }, 400)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [isDark])

  const getCard = (type: string, post: any): JSX.Element => {
    switch (type) {
      case 'event':
        return addSuspense(<EventCard post={post} />)
      case 'route':
        return addSuspense(<RouteCard post={post} />)
      default:
        return addSuspense(<PostCard post={post} />)
    }
  }

  return (
    <Layout location={location}>
      <PostHeader frontmatter={frontmatter} url={postLink} />
      <article className="blog-post" itemScope itemType="http://schema.org/Article">
        <PostBody dangerouslySetInnerHTML={{ __html: post.html }} itemProp="articleBody" />
      </article>
      <hr />
      <div style={{ margin: '2rem 0 2rem 0' }}>
        <SectionTitle>Share Post</SectionTitle>
        <ShareButtons title={title} url={postLink} tags={[]} />
      </div>
      <hr />
      <Footer>
        <SectionHeader title={`More ${Capitalize(type)}s`} subtitle={textConstants?.SUBTITLE ?? ''} type={type} />
        <Nav className="blog-post-nav">
          <ul>
            <li>
              <Reveal effect="fade-up">{Boolean(previous) && getCard(type, previous)}</Reveal>
            </li>
            <li>
              <Reveal effect="fade-up">{Boolean(next) && getCard(type, next)}</Reveal>
            </li>
          </ul>
        </Nav>
        <hr />
        <CommentsContainer>
          <Disqus key={themeKey} config={disqusConfig} />
        </CommentsContainer>
      </Footer>
    </Layout>
  )
}

interface Breadcrumbs {
  '@context': string
  '@type': string
  itemListElement: Array<{ '@type': string; position: number; name: string; item: string }>
}

interface Article {
  '@context': string
  '@type': string
  headline: string
  description: string
  image: any
  url: string
  author: { '@type': string; name: string }
  dateModified: string
  isFamilyFriendly: boolean
  wordCount: number
}

export function Head({ location, data: { site, markdownRemark: post } }: Props): JSX.Element {
  const { frontmatter } = post
  const featuredImage = frontmatter.hero_image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
  const metaImage = `${site?.siteMetadata?.siteUrl}${featuredImage ?? '/images/heroes/coverLake.jpg'}`
  const wordCount = post.html
    .replace(/<[^>]*>/g, '')
    .split(/\s+/)
    .filter((word: string) => word !== '').length

  const structuredDataBreadcrumbs: Breadcrumbs = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: site?.siteMetadata?.siteUrl,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: `${frontmatter.type}s`,
        item: `${site?.siteMetadata?.siteUrl}/${frontmatter.type}s`,
      },
    ],
  }

  const structuredDataArticle: Article = {
    '@context': 'http://schema.org',
    '@type': 'Article',
    headline: frontmatter.title,
    description: frontmatter.description ?? post.excerpt,
    image: metaImage,
    url: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
    author: { '@type': 'Person', name: 'Daniel Thurston' },
    dateModified: frontmatter.lastMod,
    isFamilyFriendly: true,
    wordCount,
  }

  return (
    <Seo
      title={frontmatter.title}
      location={location}
      description={frontmatter.description ?? post.excerpt}
      image={frontmatter.hero_image}
      schema={[structuredDataArticle, structuredDataBreadcrumbs]}
    />
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 180)
      html
      frontmatter {
        aka
        city
        country
        continent
        description
        lastMod
        title
        type
        date(formatString: "MMMM DD, YYYY")
        startdate(formatString: "MMMM DD, YYYY")
        enddate(formatString: "MMMM DD, YYYY")
        hero_image_credit_text
        hero_image_credit_link
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        startdate(formatString: "MMMM DD, YYYY")
        enddate(formatString: "MMMM DD, YYYY")
        title
        aka
        description
        type
        subtype
        duration
        city
        country
        countries
        continent
        hero_image_credit_text
        hero_image_credit_link
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        startdate(formatString: "MMMM DD, YYYY")
        enddate(formatString: "MMMM DD, YYYY")
        title
        aka
        description
        type
        subtype
        duration
        city
        country
        countries
        continent
        hero_image_credit_text
        hero_image_credit_link
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
