import * as React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import useWindowSize from '../../../utils/hooks/useWindowSize'
import Capitalize from '../../../utils/Capitalize'
import Reveal from '../../../utils/Reveal'
import { BrandLink } from '../../common/buttons/BrandButton'

const HeaderContainer = styled.header<{ $isSidePanel: boolean }>`
  display: flex;
  flex-direction: column;
  margin: auto auto 0.5rem auto;
  padding: 0 1.5rem 1rem 1.5rem;
  position: relative;
  @media (min-width: 768px) {
    margin: ${({ $isSidePanel }) => ($isSidePanel ? '0' : 'auto')};
    padding: ${({ $isSidePanel }) => ($isSidePanel ? '0 1.5rem 1.5rem 1.5rem' : '1.5rem')};
  }
`

const TopText = styled.div`
  @media (min-width: 480px) {
    margin: auto;
  }
`

const SectionTitle = styled.h1`
  color: ${props => props.theme.black200greyscale100};
  font: 900 var(--fontSize-4) / 1.5 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-0);
  margin: 0;
  text-align: flex-start;
  transition: color 300ms ease-in-out;
  user-select: none;
  @media (min-width: 768px) {
    font-size: var(--fontSize-5);
    line-height: 1;
    margin: auto;
    text-align: center;
  }
`

const Subtitle = styled.h2<{ $isSidePanel: boolean }>`
  color: ${props => props.theme.black400greyscale300};
  font: 400 var(--fontSize-1) / 1.5 var(--font-montserrat);
  letter-spacing: var(--letterSpacing-0);
  margin: 0;
  text-align: flex-start;
  user-select: none;
  @media (min-width: 768px) {
    font-size: var(--fontSize-2);
    line-height: 1.2;
    margin: 0.5rem 0;
    text-align: center;
    ${({ $isSidePanel }) =>
      $isSidePanel &&
      css`
        margin-bottom: 1rem;
      `}
  }
`

const ViewAllLink = styled(Link)`
  background-color: ${props => props.theme.greyscale100secondary800};
  box-sizing: border-box;
  border: 2px solid ${props => props.theme.primary700to300};
  border-radius: 20px;
  box-shadow: var(--shadow-tight);
  color: ${props => props.theme.black400greyscale300};
  display: flex;
  font: 500 var(--fontSize-0) / 1 var(--font-montserrat);
  margin: auto 0 auto auto;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  transition: transform 50ms ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  will-change: transform;
  &:active {
    transform: scale(0.95);
  }
`

const ChildrenContainer = styled.div<{ $isSidePanel: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (min-width: 768px) {
    ${({ $isSidePanel }) =>
      $isSidePanel &&
      css`
        flex-direction: column;
        margin: 1rem;
      `}
  }
`

interface Props {
  children?: any
  title: string
  subtitle?: string
  type?: 'events' | 'articles' | 'routes' | 'destinations'
  isSidePanel?: boolean
}

export default function SectionHeader({ children, type, subtitle, title, isSidePanel = false }: Props): JSX.Element {
  const size = useWindowSize()
  let checkedType
  let link
  if (type !== undefined) {
    checkedType = type.endsWith('s') ? type.toLowerCase() : `${type.toLowerCase()}s`
    link = checkedType === 'destinations' ? 'europe' : checkedType
  }

  return (
    <HeaderContainer $isSidePanel={isSidePanel}>
      <div style={{ display: 'flex' }}>
        <TopText>
          <Reveal effect="fade-in">
            <SectionTitle>{title}</SectionTitle>
            {Boolean(subtitle) && <Subtitle $isSidePanel={isSidePanel}>{subtitle}</Subtitle>}
          </Reveal>
        </TopText>

        {size.width < 768 && type !== undefined && (
          <div style={{ margin: 'auto 0 auto auto' }}>
            <Reveal effect="pulse-in">
              <ViewAllLink to={`/${link}`}>VIEW ALL</ViewAllLink>
            </Reveal>
          </div>
        )}
      </div>

      {size.width >= 768 && (children !== undefined || type !== undefined) && (
        <ChildrenContainer $isSidePanel={isSidePanel}>
          <Reveal effect="pulse-in" delay={0}>
            {children}
          </Reveal>
          {checkedType !== undefined && (
            <Reveal effect="pulse-in" delay={children === undefined ? 0 : 1}>
              <BrandLink to={`/${checkedType}`}>{`See all ${Capitalize(checkedType)}`}</BrandLink>
            </Reveal>
          )}
        </ChildrenContainer>
      )}
    </HeaderContainer>
  )
}
